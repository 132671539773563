module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-preact/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["en","da"],"redirect":false,"siteUrl":"https://bruce-willis.rocks","defaultLanguage":"da","i18nextOptions":{"lowerCaseLng":true,"interpolation":{"escapeValue":false},"keySeparator":".","nsSeparator":":"},"pages":[{"matchPath":"/:lang/","getLanguageFromPath":true,"excludeLanguages":["da"]}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Bruce Willis","short_name":"Bruce Willis","start_url":"/","background_color":"#080705","theme_color":"#7c6f6c","display":"standalone","orientation":"portrait","icon":"src/images/favicon.png","icons":[{"src":"../icons/icon-48x48.png","sizes":"48x48","type":"image/png","purpose":"maskable any"},{"src":"../icons/icon-72x72.png","sizes":"72x72","type":"image/png","purpose":"maskable any"},{"src":"../icons/icon-96x96.png","sizes":"96x96","type":"image/png","purpose":"maskable any"},{"src":"../icons/icon-144x144.png","sizes":"144x144","type":"image/png","purpose":"maskable any"},{"src":"../icons/icon-192x192.png","sizes":"192x192","type":"image/png","purpose":"maskable any"},{"src":"../icons/icon-256x256.png","sizes":"256x256","type":"image/png","purpose":"maskable any"},{"src":"../icons/icon-384x384.png","sizes":"384x384","type":"image/png","purpose":"maskable any"},{"src":"../icons/icon-512x512.png","sizes":"512x512","type":"image/png","purpose":"maskable any"}],"cache_busting_mode":"none","legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
